
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































$number-width: 2rem;
$bp: l;

.process-nav {
  background: $c-white;
  margin-top: $spacing * 2;

  @include mq($bp) {
    margin-bottom: $spacing * 3.5;

    &.can-stick {
      position: sticky;
      z-index: 1;
      top: calc(var(--header-height-down) - 1px);
      transition: box-shadow 1s $ease-80-20;
    }

    &.is-sticky {
      box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
    }
  }
}

.process-nav__list {
  @extend %text-uppercase;

  margin-top: 0;
  margin-bottom: 0;
  background: $c-white;
  list-style-type: none;
  counter-reset: process-nav;

  @include mq($bp) {
    display: flex;
  }
}

.process-nav__list__item-outer {
  flex: 1;
}

.process-nav__list__item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  font-size: 1.6rem;
  text-decoration: none;
  counter-increment: process-nav;
  white-space: nowrap;

  .icon {
    margin-right: $spacing * 0.5;
  }

  &:focus-visible {
    outline: 2px solid $c-gray-lighter;
  }

  &::before,
  li:last-child &::after {
    @extend %ff-alt;
    @extend %text-center;

    content: counter(process-nav);
    position: absolute;
    z-index: 1;
    bottom: 50%;
    left: 0;
    width: $number-width;
    height: $number-width;
    font-size: 1.4rem;
    line-height: $number-width;
    background: $c-gray-lighter;
    border-radius: 50%;
    transform: translateY(50%);
    transition: opacity 0.2s;
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: calc(#{$number-width * 0.5} - 0.1rem);
    width: 0.2rem;
    height: 100%;
    background: $c-gray-lighter;
  }

  li:last-child &::after {
    content: none;
    top: initial;
    bottom: 0;
    right: 0;
    left: initial;
    background-image: url('../../assets/svg/ui/check.svg');
    background-size: contain;
    transform: translateY(50%);
  }

  @include mq(l) {
    justify-content: center;
    padding-left: $spacing * 0.5;
    border-bottom: 0.2rem solid $c-gray-lighter;
    border-left: 0;

    &::before {
      top: initial;
      bottom: 0;
      transform: translateY(50%);
    }

    &::after {
      content: none;
    }

    li:last-child &::after {
      content: '';
    }

    .is-active & {
      color: $c-mustard;
      border-color: $c-mustard;
    }

    .is-sticky & {
      &::before,
      &::after {
        opacity: 0;
      }
    }
  }
}
